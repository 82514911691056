import axios from "axios";
import { getToken } from "@/http/auth";

let baseURL="";
let webSocket;
let baseIp;
const getBaseUrl = function () {
  $.ajax({
      type : "get",
      async: false, // fasle表示同步请求，true表示异步请求
      url : "./config.json",
      data : {},
      success : function(result:any) {
          baseURL = result.url;
          webSocket = result.webSocket;
          baseIp=result.ip;
      },
      error : function(e:any){
          console.log(e);
      }
  });
};

//动态获取ip
getBaseUrl();

axios.defaults.baseURL = baseIp;
// create an axios instance
const request = axios.create({
  // baseURL: process.env.VUE_APP_URL,
  // baseURL: 'http://10.1.1.188:8089/',
  // withCredentials: true,  // 跨域请求时发送cookie
  timeout: 60000,
});

// 请求拦截器
request.interceptors.request.use(
  (config: any) => {
const TOKEN = getToken(); // 获取token
    if (TOKEN) {
      config.headers["Authorization"] = TOKEN;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);
//设置请求全局拦截
request.interceptors.request.use((config) => {
  // console.log(config);
  //为非会议室相关请求设置baseUrl
  if(config.url?.includes("/v1")){
    config.baseURL = "http://10.1.4.246/";
  }else if(config.url?.includes("/AccessToken/SendInfo")){
    config.baseURL = "http://172.20.10.2:8089/";
  }else if (
    config.url !== "/getMeetingItem.php" &&
    config.url !== "/getMeetingRoomList.php"
  ) {
    // config.baseURL = "http://10.1.4.46:8089/";
     config.baseURL = baseURL;
  }
  return config;
}); 
// 响应拦截器
request.interceptors.response.use(
  (response) => {
    //console.log(response)
    if(response.headers.authorization != null)
      return response;
    else
      return response.data;
  },
  (error) => {
    const data = error.response.data;
    const status = error.response.status;

    // 对不同状态码进行管理
    if (status === 401) {
      console.log("登录已过期");
    } else if (status === 500) {
      console.log("服务器错误");
    } else {
      console.log("请求错误状态码:", status);
    }
    return Promise.reject(data.error);
  }
);

export default request;
